<template>
  <div v-if="isPreviewEnabled" class="audience-widget">
    <PrimeVueButton
      type="button"
      label="Audience Widget"
      rounded
      @click="popoverToggle"
    />
    <PrimeVuePopover ref="popover" class="audience-widget-popover">
      <span class="audience-widget-popover__headline">Audiences</span>
      <ul>
        <li
          v-for="audience of allAudiences"
          :key="audience.sys.id"
          v-tooltip="audience.fields.description"
          class="audience-widget-popover__item mb-2"
        >
          <PrimeVueCheckbox
            v-model="selectedAudiences"
            :input-id="audience.sys.id"
            name="audience"
            size="small"
            :value="audience.sys.id"
          />
          <label :for="audience.sys.id" class="p-label">{{
            audience.fields.internalName
          }}</label>
        </li>
      </ul>
    </PrimeVuePopover>
  </div>
</template>

<script setup lang="ts">
import { useAudiences } from '~/composables/useAudiences';
import { type IAudience } from '~/lib/ContentfulService';

const {
  public: { isPreviewEnabled }
} = useRuntimeConfig();

const { $matchingAudiences, $locale } = useNuxtApp();

const allAudiences: IAudience[] = await (async () => {
  if (!isPreviewEnabled) {
    return [];
  }
  return (await useAudiences($locale.value)).value?.entries ?? [];
})();

const popover = ref();
const popoverToggle = (event: Event) => {
  popover.value.toggle(event);
};

const selectedAudiences = ref<string[]>($matchingAudiences.value);

watch(selectedAudiences, (newValue, oldValue) => {
  if (newValue.length > oldValue.length) {
    // added one
    const added = newValue.filter((x) => !oldValue.includes(x));
    $matchingAudiences.value.push(added[0]);
  } else {
    // removed one
    const removed = oldValue.filter((x) => !newValue.includes(x));
    for (const key in $matchingAudiences.value) {
      const audienceId = $matchingAudiences.value[key];
      if (audienceId === removed[0]) {
        delete $matchingAudiences.value[key];
        break;
      }
    }
  }
});
</script>

<style>
/* overwrite the primevue theme styles*/
.audience-widget {
  position: fixed !important;
  bottom: 25px;
  right: 25px;
  z-index: 999;
}

.audience-widget-popover {
  position: fixed !important;
  bottom: 72px !important;
  right: 25px !important;
  top: unset !important;
  left: unset !important;
  display: block !important;
  overflow-y: auto;
  max-height: calc(100% - 80px);
}

.audience-widget-popover::before {
  display: none !important;
}

.audience-widget-popover::after {
  display: none !important;
}

.audience-widget-popover > .p-popover-content {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.audience-widget-popover__headline {
  font-weight: 600;
  font-size: 12px;
}

.audience-widget-popover__item {
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
}
</style>
